import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

// import Bookdemo from "../components/bookdemo"
import Contactus from "../components/contactus"
// import ContentTitle from "../components/contenttitle"
// import HeaderButtons from "../components/headerbuttons"
import FooterButtons from "../components/footerbuttons"

import "../components/pages-css/index.css"
// import BookDemoBlock from "../components/bookdemoblock"

const IndexPage = () => (
  <Layout >
    <SEO title="Aged Care Governance Assurance Program" />
    {/*<ContentTitle subtitle={"Inclusive of Standard 8 Organisational Governance"} buttonbook={true} buttondemo={true}>Aged Care Governance Assurance Program</ContentTitle>*/}
    <div className={`page-content`}>
      {/*<BookDemoBlock />*/}
      {/*<HeaderButtons />*/}
      <div className='page-iframe'>
        <iframe src="https://calendly.com/fimercer/aged-care-governance-assurance" className='content-iframe'></iframe>
      </div>
      <div className='page-content'>
        <div className={'section-readingheading'}>
          <h3>Watch our insights session on our Standard 8 Assurance Program</h3>
          Fi Mercer, CEO and Founder of Governance Evaluator recently talked with Dorothy Nycz, CEO of ECH Adelaide and Brenda Head, Quality and Compliance Manager of ECH Adelaide
          about the empowering opportunities and the awareness of the challenges provided by going through this Standard 8 assurance program for Clinical Governance.
        </div>
        <div className={'standard8-video'}>
          <video controls>
            <source type="video/mp4" src="https://governanceevaluator.s3.ap-southeast-2.amazonaws.com/video/standard_8_assurance_insights_program.mp4"  type="video/mp4" />
          </video>
        </div>
        <div className={'standard8-block-quote'}>
          <Image src="brendan-moore.png" alt="Brendan Moore - Leading Age Services Australia" className={"quoteimage"} />
          <h3>&ldquo;Standard 8 is the second most not met standard and the most misunderstood&rdquo;</h3>
          <p className={'nobottommargin'}><strong>Brendan Moore</strong><br/>Formally of<br/>Leading Age Services Australia</p>
        </div>
        <section className={'section-problem'}>
          <p>According to the Royal Commission, Standard 8, ‘Organisational Governance’, is the most misunderstood and second most ‘not met’ standard in aged care organisations. This is due to consumer engagement, risk, organisation wide systems, culture and clinical governance not being evident consistently and contextually throughout all levels of the organisation.</p>
          <p>Currently, Aged Care Boards, Directors Executives and Management Teams are not feeling confident or assured about passing the Aged Care Quality Standards accreditation, especially Standard 8, ‘Organisational Governance’.</p>
          <p>They want to understand their safety and quality culture and risk on all levels of the organisation. The element that is missing is how to gain this understanding in a simple and effective manner, without breaking the bank.</p>
        </section>
        <section className={'section-solution'}>
          <p>We are pleased to partner with Leading Age Services Australia (LASA) to launch our new Aged Care Governance Assurance Program – inclusive of the Standard 8, Organisational Governance.</p>
          <p>Built on a review and development platform, this program helps Boards, Directors, Executives and Management Teams be confident organisation wide for compliance with Standard 8, for not only passing accreditation but for a safe and quality culture.</p>
          <p className={'nobottommargin'}>The trusted, easy to use online platform contains the latest legislative content supported by aged care and governance industry experts, with the ability to be scaled to suit organisations of any size.</p>
        </section>
      </div>

      <section className={'section-outcome'}>
        <div className={'standard8-block-quote2 clear'}>
          <Image src="david-panter.png" alt="David Panter - CEO, ECH Adelaide" className={"quoteimage left"}/>
          <h3>&ldquo;A friendly, easy to use online process giving us key insights for governance improvement&rdquo;</h3>
          <p className={'nobottommargin'}><strong>David Panter</strong><br/>CEO,<br/>ECH Adelaide</p>
        </div>
        <p>Our centralised platform ensures continual assurance through interactive reporting dashboards, allowing for organisation wide data insights to meet ongoing development requirements, risks and achievements.</p>
        <p>All of which are supported by our innovative education, development resources and services.</p>
      </section>
      <FooterButtons />
    </div>
    <Contactus/>
  </Layout>
)

export default IndexPage
