import React, { Component } from "react"
import { connect } from "react-redux"

import "./footerbuttons.css"
import { toggleContactModal } from "../state/app"

class FooterButtons extends Component {
  handleContactClick = (e) => {
    window.gtag("event", "button-click", { 'event_category': 'engagement', 'event_label': 'open_contact_form'});
    this.props.dispatch(toggleContactModal(true));
  }

  render() {
    return (
      <>
        <div className={"footerbuttons clear"}>

          <div className={"fbthankyou-button"}>
            <div role={'button'} tabIndex={0} className={`fbbutton`} onClick={(e) => {this.handleContactClick(e)}} >Contact Us</div>
          </div>
          <div className={"fbthankyou-button"}>
            <a href="tel:0488525460"><div className={`fbbutton`} >Call us on 0488 525 460</div></a>
          </div>
        </div>
        <div className={"clear"}></div>
      </>
    )
  }
}

export default connect(state => ({}), null)(FooterButtons)