import React, { Component } from "react"
import "./inputfieldtext.css"

class InputFieldText extends Component {
  render() {
    return (
      <div className="inputfield">
        <textarea className={`textfieldinput ${this.props.ferror}`} name={this.props.fname} onChange={this.props.fOnChangefn} placeholder={this.props.fplaceholder} value={this.props.fvalue}></textarea>
      </div>
    )
  }
}

export default InputFieldText