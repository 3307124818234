import React, { Component } from "react"
import "./contactus.css"
import { connect } from 'react-redux'
import ReactModal from "react-modal"

import { toggleContactModal } from "../state/app"
import ContactUsModal from "./contactusmodal"

const customModalStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    maxHeight: '100vh'
  }
};

class Contactus extends Component {
  handleModalClose = event => {
    this.props.dispatch(toggleContactModal(false))
  }

  render() {
    return (
      <ReactModal
        closeTimeoutMS={500}
        isOpen={this.props.contactModalActive}
        onRequestClose={this.handleModalClose}
        style={customModalStyles}
        contentLabel="Contact Us"
        ariaHideApp={false}
      >
        <ContactUsModal/>
      </ReactModal>
    )
  }
}

export default connect(state => ({
  contactModalActive: state.app.contactModalActive
}), null)(Contactus)