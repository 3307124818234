import React, { Component } from "react"
import "./inputfield.css"

class InputField extends Component {
  render() {
   return (
    <div className="inputfield">
      <input type="text" className={`inputfieldinput ${this.props.ferror}`} name={this.props.fname} value={this.props.fvalue}
             onChange={this.props.fOnChangefn} placeholder={this.props.fplaceholder} />
    </div>
   )
  }
}

export default InputField