import React, { Component } from "react"
import { connect } from "react-redux"
import {
  toggleContactModal,
  toggleContactSubmit,
  submitContactForm,
  updateFormField,
  resetContactForm,
  toggleBothModals
} from "../state/app"

import "./contactusmodal.css"
import InputField from "./inputfield"
import InputFieldText from "./inputfieldtext"

const FormErrors = (props) => {
  if (props.display !== true) {
    return (
      <div className="demoModalErrorMessage">
        An error has occurred when submitting your request, please correct and/or complete the highlighted fields with valid information
      </div>
    )
  }
  return null
}

class ContactUsModal extends Component {
  handleModalClose = e => {
    this.props.dispatch(toggleContactModal(false))
  }

  handleFormChange = e => {
    this.props.dispatch(updateFormField({ name: e.target.name , value: e.target.value }))
  }

  handleFormSubmit = e => {
    window.gtag("event", "button-click", { 'event_category': 'engagement', 'event_label': 'submit_contact_form'});
    this.props.dispatch(toggleContactSubmit(false));
    this.props.dispatch(submitContactForm());
  }

  handleFormReset = e => {
    window.gtag("event", "button-click", { 'event_category': 'engagement', 'event_label': 'reset_contact_form'});
    this.props.dispatch(resetContactForm());
  }

  handleModalSwitch = e => {
    window.gtag("event", "button-click", { 'event_category': 'engagement', 'event_label': 'open_booking_form'});
    this.props.dispatch(toggleBothModals({contact:false, demo:true}));
  }

  doNothing = e => {
    return null;
  }

  render() {
    if(!this.props.contactformsent) {
      return(
        <>
          <button className={'contactModalClose'} onClick={this.handleModalClose}>Close</button>
          <section className={'section-contact-us'}>
            <div className={'contactModalTitle'}>
              <h2>Contact Us</h2>
            </div>
            <div className={'contactModalContent'}>
              <p>
                Not quite ready for an insights session? But want to reach out and contact us with any
                questions you might have about our assurance program or your governance risks?
              </p>
              <p>
                Please complete the form below and we'll be in touch!
              </p>

              <div className={'contactform'}>
                <div className={'contactinputs'}>
                  <h4>Your Details:</h4>
                  <FormErrors display={this.props.contactformvalid} />
                  <div>
                    <InputField fname="firstname" fvalue={this.props.form.firstname}
                                ferror={this.props.formerrors.firstname} fOnChangefn={this.handleFormChange}
                                fplaceholder="First Name *" />
                    <InputField fname="lastname" fvalue={this.props.form.lastname}
                                ferror={this.props.formerrors.lastname} fOnChangefn={this.handleFormChange}
                                fplaceholder="Last Name *" />
                    <InputField fname="email" fvalue={this.props.form.email}
                                ferror={this.props.formerrors.email} fOnChangefn={this.handleFormChange}
                                fplaceholder="Email *" />
                    <InputField fname="phone" fvalue={this.props.form.phone}
                                ferror={this.props.formerrors.phone} fOnChangefn={this.handleFormChange}
                                fplaceholder="Phone *" />
                    <InputField fname="organisation" fvalue={this.props.form.organisation}
                                ferror={this.props.formerrors.organisation} fOnChangefn={this.handleFormChange}
                                fplaceholder="Organisation *" />
                    <InputField fname="jobtitle" fvalue={this.props.form.jobtitle}
                                ferror={this.props.formerrors.jobtitle} fOnChangefn={this.handleFormChange}
                                fplaceholder="Job Title *" />
                  </div>
                  <div className={'contactdisclaimer'}>
                    * Required field.<br />
                  </div>
                </div>
                <div className={'contacttext'}>
                  <h4>Your message:</h4>
                  <InputFieldText fname="comment" fvalue={this.props.form.comment}
                                  ferror={this.props.formerrors.comment} fOnChangefn={this.handleFormChange}
                                  fplaceholder="Your question/message" />
                </div>
              </div>
            </div>
            <div className={'contactbuttoncontainer clear'}>
              <div role={'button'} tabIndex={0} id={'contactbutton'} className={`contactbutton ${this.props.contactformsubmit}`} onClick={(e) => { this.props.contactformsubmit ? this.handleFormSubmit() : this.doNothing()}}>Submit your message</div>
            </div>
          </section>
        </>
    )} else {
      window.gtag("event", "contact-form-submit", { 'event_category': 'conversion', 'event_label': 'contact_form_complete'});
      return(
        <>
          <button className={'contactModalClose'} onClick={this.handleModalClose}>Close</button>
          <section className={'section-contact-us'}>
            <div className={'contactModalTitle'}>
              <h2>Contact Us</h2>
            </div>
            <div className={'contactModalContent'}>
              <p>
                Thank you for contacting us regarding our Aged Care Governance Assurance Program.
              </p>
              <p>
                You will shortly receive an acknowledgement of your email and we will endeavour to respond to your enquiry as soon as possible.
              </p>
              <p>
                You are still welcome to book an insights session of our program, and can do so by clicking the button below.
              </p>
              <br/>
              <div>
                {/*<div className={"bdthankyou-button"}>*/}
                {/*  <div role={'button'} tabIndex={0} className={`bookdemobutton ${this.props.demoformsubmit}`} onClick={(e) => {this.handleModalSwitch()}} >Book an insights session</div>*/}
                {/*</div>*/}
                <div className={"bdthankyou-button"}>
                  <div role={'button'} tabIndex={0} className={`bookdemobutton ${this.props.demoformsubmit}`} onClick={(e) => {this.handleFormReset()} } >Send another message</div>
                </div>
                <div className={"bdthankyou-button"}>
                  <a href="tel:0488525460" role={'button'} tabIndex={0}><div className={`bookdemobutton ${this.props.demoformsubmit}`} >Call us on 0488 525 460</div></a>
                </div>
              </div>
              <div className={"clear"}></div>
            </div>
          </section>
        </>
    )}
  }
}


export default connect(state => ({
  form: state.app.form,
  formerrors : state.app.formerrors,
  contactformvalid : state.app.contactformvalid,
  contactformsent : state.app.contactformsent,
  contactformsubmit : state.app.contactformsubmit,
}), null)(ContactUsModal)